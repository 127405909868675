<template>
  <div class="flex mb-32">
    <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">
      <div
        class="card border border-blue-200 pt-16 pb-6 px-6 md:px-16 -mx-6 sm:mx-0"
      >
        <div class="font-medium text-xl mb-5">Add Merchant</div>

        <form @submit.prevent="submit">
          <template v-if="getFormError(form)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(form) }}</span>
            </div>
          </template>

          <form-group
            name="business_name"
            :form="form"
            v-model="form.data.business_name.value"
          >
            Business Name
          </form-group>

          <form-group
            type="email"
            name="workplace_email"
            :form="form"
            v-model="form.data.workplace_email.value"
          >
            Business Email (Optional)
          </form-group>

          <div class="grid grid-cols-1 sm: grid-cols-2 gap-4">
            <div class="col-span-1">
              <form-group
                leftIcon="person-outline"
                name="first_name"
                :form="form"
                v-model="form.data.first_name.value"
              >
                Admin First Name
              </form-group>
            </div>
            <div class="col-span-1">
              <form-group
                leftIcon="person-outline"
                name="last_name"
                :form="form"
                v-model="form.data.last_name.value"
              >
                Admin Last Name
              </form-group>
            </div>
          </div>

          <div class="grid grid-cols-1 sm: grid-cols-2 gap-4">
            <div class="col-span-1">
              <form-group
                type="select"
                :options="industryOptions"
                leftIcon="business-outline"
                name="industry_id"
                :form="form"
                v-model="form.data.industry_id.value"
              >
                Industry
              </form-group>
            </div>
            <div class="col-span-1">
              <form-group
                leftIcon="person-outline"
                name="website"
                :form="form"
                v-model="form.data.website.value"
              >
                Website
              </form-group>
            </div>
          </div>

          <div class="grid grid-cols-1 sm: grid-cols-2 gap-4">
            <div class="col-span-1">
              <form-group
                type="email"
                name="email"
                :form="form"
                v-model="form.data.email.value"
              >
                Email Address
              </form-group>
            </div>
            <div class="col-span-1">
              <form-group
                type="phone"
                name="phone"
                :form="form"
                v-model="form.data.phone.value"
              >
                Phone Number
              </form-group>
            </div>
          </div>

          <form-group
            name="description"
            :form="form"
            v-model="form.data.description.value"
          >
            Description (optional)
          </form-group>

          <div class="mb-5">
            <h5 class="text-10 mb-2 text-gray-500">Image</h5>
            <dropzone
              class="mb-0 mr-2 w-full"
              accept=".png,.jpg,.jpeg"
              v-model="form.data.image.value"
            />
          </div>

          <div class="flex flex-row">
            <label class="custom-switch">
              <input
                type="checkbox"
                class="custom-switch-input"
                v-model="form.data.featured.value"
              />
              <span class="custom-switch-label"></span>
            </label>
            <span class="text-sm mt-1 ml-1">Add As Featured Category</span>
          </div>

          <div class="text-right mt-4">
            <button type="submit" class="btn btn-blue" :disabled="form.loading">
              <sm-loader-white v-if="form.loading" />
              <span v-else>Add Merchant</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: '',
      industries: this.$options.resource([]),
      form: this.$options.basicForm([
        { name: 'business_name', rules: 'required|aplpha' },
        { name: 'workplace_email', rules: 'nullable|aplpha' },
        { name: 'first_name', rules: 'required|aplpha' },
        { name: 'last_name', rules: 'required|aplpha' },
        { name: 'industry_id', rules: 'required' },
        { name: 'website', rules: 'nullable' },
        { name: 'image', value: null, rules: 'nullable' },
        { name: 'email', rules: 'required|email' },
        { name: 'phone', rules: 'required|phone' },
        { name: 'description', rules: 'nullable' },
        { name: 'featured', rules: 'nullable' }
      ]),
      banksLoading: false
    };
  },
  computed: {
    industryOptions() {
      return this.industries?.data?.length
        ? this.industries.data?.map(industry => ({
            title: industry.name,
            value: industry.id
          }))
        : [];
    }
  },
  beforeMount() {
    this.getIndustries();
  },
  methods: {
    async submit() {
      if (!this.validateForm(this.form)) return false;
      this.form.error = false;
      this.form.loading = true;

      const image =
        this.form.data.image.value === null ? '' : this.form.data.image.value;

      const data = new FormData();
      data.append('business_name', this.form.data.business_name.value);
      data.append('workplace_email', this.form.data.workplace_email.value);
      data.append('first_name', this.form.data.first_name.value);
      data.append('last_name', this.form.data.last_name.value);
      data.append('industry_id', this.form.data.industry_id.value);
      data.append('website', this.form.data.website.value);
      data.append('email', this.form.data.email.value);
      data.append('phone', this.form.data.phone.value);
      data.append('image', image);
      data.append('featured', this.form.data.featured.value ? 1 : 0);
      data.append('description', this.form.data.description.value);

      await this.$post({
        url: `${this.$baseurl}/admin/merchants`,
        headers: this.headers,
        data: data,
        success: () => {
          this.resetForm();
          this.$success({
            title: 'Success!',
            body: 'Merchant Added Successfully',
            button: 'Okay',
            onClose: () => this.$router.push({ name: 'merchants' })
          });
        },
        error: error => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.loading = false;
    },
    async getIndustries() {
      this.industries.loading = true;
      await this.sendRequest('admin.merchants.category', {
        success: response => (this.industries.data = response.data.data),
        error: error => console.log(error)
      });
      this.industries.loading = false;
    }
  }
};
</script>
